import React, { useEffect, useState } from 'react'
import { CurrencySymbol } from '../../../utils/currency-symbol'
import { retrieveConfig } from '../../../config/config'

const FormField = ({ customField, errors, inputChange, register, formType }) => {
    const error = errors && errors[customField.name]
    const [currencyCode, setCurrencyCode] = useState(0)
    const [fieldConfig, setFieldConfig] = useState(0)
    const generalInputClass = 'w-full py-1 border border-slate-200 rounded-lg px-1 focus:outline-none' // define your general input class here
    const errorClass = 'ring ring-red-300 focus:ring ring-red-300 hover:shadow'
    const iconedFieldClass = customField.isCurrency ? ' pl-8 ' : ''

    useEffect(() => {
        async function fetchData() {
            try {
                const config = await retrieveConfig()
                // Batch state updates to avoid multiple renders
                const updatedFieldConfig = config.formFields.find(field => field.name === customField.name)
                setCurrencyCode(config.currencyCode)
                setFieldConfig(updatedFieldConfig)
            } catch (error) {
                console.error('Error fetching config:', error)
            }
        }
        fetchData()
    }, [customField]) // Only depend on customField to avoid infinite loops

    return (
        <label htmlFor="code" className="mt-3">
            <p className="font-medium text-slate-700 pb-1">{customField.label}</p>
            <div className="relative">
                <CurrencySymbol isCurrency={customField.isCurrency} currencyCode={currencyCode} />
                <input
                    id={customField.name}
                    name={customField.name}
                    type={customField.type}
                    data-testid={customField.name}
                    maxLength={customField.validation?.maxLength}
                    autoComplete="off"
                    className={generalInputClass + (error ? errorClass : '') + iconedFieldClass}
                    disabled={formType == 'secure_link' && fieldConfig.locked}
                    onKeyUp={inputChange}
                    {...register(customField.name)}
                />
                {error && <p className="text-red-500 text-sm mt-2">{error.message}</p>}
            </div>
        </label>
    )
}

export default FormField
