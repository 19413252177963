import { useForm } from 'react-hook-form'
import _get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'

import Card from './PayTypeMessage/card'
import Bank from './PayTypeMessage/bank'
import EmailSentMessage from './email-sent-message'
import { retrieveConfig } from '../../config/config'
import { CurrencySymbol } from '../../utils/currency-symbol'

export default function PaymentSuccess(props) {
    const [config, setConfig] = useState(null)
    const cdnHost = window.env.REACT_APP_CDN_HOST
    useEffect(() => {
        async function loadConfig() {
            try {
                const result = await retrieveConfig()
                setConfig(result)
            } catch (error) {
                console.error('Error loading config:', error)
            }
        }
        loadConfig()
    }, [])

    // Get the primary color from the config
    const primaryColor = config?.colorTheme?.primaryColor ?? '#334155'

    const [receiptSent, setReceiptSent] = useState(false)
    const [isEmailError, setIsEmailError] = useState(false)
    const errorClass = 'ring ring-red-300 focus:ring ring-red-300 hover:shadow '
    const generalInputClass = 'w-full py-1 border border-slate-200 rounded-lg px-1 focus:outline-none '
    const hasEmailReceipt = config?.enableEmailReceipt ?? false

    const schema = yup.object().shape({
        email: yup.string().required('Value is required').max(255, 'Value must be no larger then 255 characters long'),
    })
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async data => {
        axios
            .post(window.env.REACT_APP_DEMO_BACKEND_URL + '/payment/receipt/' + props.chargeId + '/send', {
                receipientEmail: data.email,
            })
            .then(() => {
                setReceiptSent(true)
            })
            .catch(() => {
                setReceiptSent(true)
                setIsEmailError(true)

                window.setTimeout(() => {
                    setReceiptSent(false)
                    setIsEmailError(false)
                    reset()
                }, 5000)
            })
    }

    if (!props.visible) {
        return null
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '600px',
            margin: '23px 22px 18px 16px',
            overflow: 'hidden',
        },
        header: {
            background: '#E6E6E6',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '24px',
        },
        icon: {
            width: '80px',
            height: '80px',
            marginBottom: '16px',
        },
        title: {
            fontSize: '24px',
            fontWeight: '700',
            color: '#4da348',
            marginBottom: '8px',
            textAlign: 'center',
        },
    }

    const transSuccessIcon = cdnHost + '/app/transaction_success.svg'
    return (
        <div className="success-box">
            <div style={styles.header}>
                <img src={transSuccessIcon} style={styles.icon} alt="Success" />

                <div style={styles.title}>Payment Approved</div>
            </div>
            <div className="successDetails">
                <DetailRow label="Receipt Number" value={props.receiptDetails.receiptNo} primaryColor={primaryColor} />
                <DetailRow label="Payment Date" value={props.receiptDetails.paymentDate} primaryColor={primaryColor} />
                <DetailRow
                    label="Payment Amount"
                    value={
                        <>
                            <CurrencySymbol isCurrency={true} currencyCode={config?.currencyCode || ''} isField={false} />
                            {props.receiptDetails.paymentAmount}
                        </>
                    }
                    primaryColor={primaryColor}
                />
                <DetailRow label="Payment Type" value={props.receiptDetails.paymentType} primaryColor={primaryColor} />
                {props.receiptDetails.paymentType === 'Card' && <Card data={props.receiptDetails} />}

                {!['Card', 'UPI', 'UPI QR'].includes(props.receiptDetails.paymentType) && <Bank data={props.receiptDetails} />}
            </div>
            {hasEmailReceipt && (
                <div className="emailReceipt">
                    {receiptSent ? (
                        <EmailSentMessage isError={isEmailError} />
                    ) : (
                        <form
                            className="receipt-form flex flex-2 flex-col bg-gray-100 pt-3 pb-2 pl-4 pr-4 m-4 space-y-2 m-8"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <h4 className="font-semibold text-lg" style={{ color: primaryColor }}>
                                Email Payment Receipt
                            </h4>

                            <label htmlFor="email" className="mt-3">
                                <p className="font-medium text-slate-700 pb-1">Email Address</p>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={generalInputClass + (errors.code ? errorClass : '')}
                                    {...register('email')}
                                />
                                {errors.email && <p className="text-red-500 text-sm mt-2">{errors.email.message}</p>}
                            </label>
                            <div className="relative">
                                <input
                                    type="submit"
                                    className="w-full py-2 font-medium text-white rounded-lg hover:shadow inline-flex space-x-2 items-center justify-center"
                                    style={{
                                        backgroundColor: primaryColor,
                                        borderColor: primaryColor,
                                    }}
                                    value="Email Receipt"
                                ></input>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    )
}

export const DetailRow = ({ label, value, icon, iconOverride, primaryColor }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
                style={{
                    display: 'flex',
                    marginBottom: '16px',
                    flexBasis: '250px',
                    fontSize: '16px',
                    color: '#6B7280',
                    fontWeight: 700,
                }}
            >
                {label}
            </div>
            <div style={{ display: 'flex', marginBottom: '16px', flexBasis: '400px' }}>
                {icon &&
                    (iconOverride ? (
                        <div style={{ width: '100px' }}>{iconOverride}</div>
                    ) : (
                        <img
                            src={icon}
                            alt=""
                            style={{
                                maxHeight: '42px',
                                marginRight: '8px',
                            }}
                        />
                    ))}
                {value && <div style={{ color: primaryColor ?? '#334155' }}>{value}</div>}
            </div>
        </div>
    )
}
