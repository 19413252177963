import React from 'react'
import { retrieveConfig } from '../../config/config'

export default class ModalHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            config: {
                showLogo: true,
                showTitle: true,
            },
            showCloseButton: false,
        }
    }

    async componentDidMount() {
        try {
            const selectedConfig = await retrieveConfig()
            if (selectedConfig) {
                this.setState({ config: selectedConfig })
            }
        } catch (error) {
            console.error('Error retrieving config:', error)
        }

        // Set a timer to show the close button after 3 seconds
        this.closeButtonTimer = setTimeout(() => {
            this.setState({ showCloseButton: true })
        }, 3000)
    }

    componentWillUnmount() {
        // Clear the timer if the component unmounts
        if (this.closeButtonTimer) {
            clearTimeout(this.closeButtonTimer)
        }
    }

    toggle = () => {
        // Destroy the DataMesh instance
        window?.DataMesh?.destroy()

        // Dispatch a custom event that PaymentModel can listen for
        document.dispatchEvent(
            new CustomEvent('closePaymentModal', {
                detail: { closed: true },
            }),
        )
    }

    render() {
        // Merge props config with state config, with state taking precedence
        const mergedConfig = { ...this.props.config, ...this.state.config }
        const { showLogo = true, showTitle = true } = mergedConfig

        const cdnHost = window.env.REACT_APP_CDN_HOST
        const urlRoot = cdnHost + '/app'

        const logoUrl = this.state.config?.logo?.url.replace('%CDN_HOST%', urlRoot)

        const primaryColor = this.state.config?.colorTheme?.primaryColor ?? '#334155'

        return (
            <div className="modal-header w-100">
                <style>
                    {`
                    @media (max-width: 312px) {
                        .modal-logo {
                            display: none !important;
                        }

                        /* Adjust title container to expand */
                        .modal-header .title-container {
                            flex-basis: 100% !important;
                            text-align: left !important;
                            padding-left: 0 !important;
                        }
                    }
                    `}
                </style>
                <div
                    className="modal-header"
                    style={{
                        display: 'flex',
                        gap: '16px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '92%',
                        marginLeft: '16px',

                        marginTop: '15px',
                        paddingBottom: '15px',
                        borderBottom: '3px solid black',
                    }}
                >
                    {/* Left: Logo */}
                    <div style={{ flex: '1', flexBasis: '160px', textAlign: 'left' }}>
                        {showLogo && (
                            <div className="modal-logo">
                                <img src={logoUrl} alt="Logo" style={{ maxHeight: '50px' }} />
                            </div>
                        )}
                    </div>

                    {/* Center: Title */}
                    <div
                        className="title-container"
                        style={{ flexGrow: 1, flexBasis: '400px', textAlign: 'center', alignSelf: 'center' }}
                    >
                        {showTitle && (
                            <h2
                                className="modal-title"
                                style={{
                                    margin: '0',
                                    fontWeight: '700',
                                    fontSize: '28px',
                                    color: primaryColor,
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.649px',
                                }}
                            >
                                Payment Options
                            </h2>
                        )}
                    </div>

                    {/* Right: Close Button */}
                    <div style={{ flexGrow: 1, flexBasis: '80px', textAlign: 'right' }}>
                        <button
                            className="modal-close-btn top-0 right-0 z-10 background-transparent font-bold uppercase text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:text-red"
                            type="button"
                            onClick={() => this.toggle()}
                            style={{
                                flexGrow: 1,
                                flexBasis: '160px',
                                marginRight: '0',
                                opacity: this.state.showCloseButton ? 1 : 0,
                                visibility: this.state.showCloseButton ? 'visible' : 'hidden',
                                transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px solid',
                                height: '44px',
                                width: '44px',
                                borderRadius: '10px',
                                borderColor: primaryColor,
                            }}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill={primaryColor}
                                    d="M14.3556 0.00444444C13.9109 0.00444444 13.4973 0.177778 13.186 0.488889L8.00945 5.66222L2.82843 0.488889C2.51713 0.177778 2.09909 0.00444444 1.65881 0.00444444C1.21854 0.00444444 0.796053 0.177778 0.484747 0.488889C0.173442 0.8 0 1.21778 0 1.65778C0 2.09778 0.173442 2.51556 0.484747 2.82667L5.66132 8L0.484747 13.1778C0.173442 13.4889 0 13.9067 0 14.3467C0 14.7867 0.173442 15.2044 0.484747 15.5156C0.796053 15.8267 1.21409 16 1.65437 16C2.09464 16 2.51268 15.8267 2.82399 15.5156L8.00056 10.3422L13.1771 15.5156C13.4884 15.8267 13.9065 16 14.3467 16C14.787 16 15.2051 15.8267 15.5164 15.5156C16.1612 14.8711 16.1612 13.8178 15.5164 13.1733L10.3398 8L15.5164 2.82667C16.1612 2.18222 16.1612 1.13333 15.5164 0.484444C15.2051 0.173333 14.787 0 14.3467 0L14.3556 0.00444444Z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
