import React from 'react'

const stylingObject = {
    loader: {
        minHeight: '493px',
        maxWidth: '500px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    loaderWheel: {
        animation: 'spin 1s infinite linear',
        border: '2px solid rgba(30, 30, 30, 0.5)',
        borderLeft: '4px solid #000',
        borderRadius: '50%',
        height: '50px',
        marginBottom: '10px',
        width: '50px',
    },
    loaderText: {
        color: '#000',
        textAlign: 'left',
        fontFamily: 'arial, sans-serif',
    },
}

export default class PaymentModelLoader extends React.Component {
    render() {
        return (
            <div data-testid="loader-container" style={stylingObject.loader}>
                <div data-testid="loader-wheel" style={stylingObject.loaderWheel}></div>
                <div data-testid="loader-text" className="loader-text" style={stylingObject.loaderText}></div>
            </div>
        )
    }
}
