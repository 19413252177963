import React from 'react'

const containerStyle = {
    minHeight: '493px',
    maxWidth: '500px',
    overflowAnchor: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
}

const errorTextStyle = {
    color: '#939393',
    textAlign: 'center',
    fontFamily: 'arial, sans-serif',
    fontSize: '24px',
}

export default class GeneralError extends React.Component {
    render() {
        if (!this.props.visible) {
            return null
        }
        return (
            <div data-testid="error-container" style={containerStyle}>
                <div data-testid="error-text" className="error-text" style={errorTextStyle}>
                    {this.props.message}
                </div>
            </div>
        )
    }
}
