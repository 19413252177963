import React, { useState, useEffect } from 'react'
import _get from 'lodash/get'
import { retrieveConfig } from '../../config/config'

export default function PaymentFailure(props) {
    const [config, setConfig] = useState(null)
    const cdnHost = window.env.REACT_APP_CDN_HOST

    useEffect(() => {
        async function loadConfig() {
            try {
                const result = await retrieveConfig()
                setConfig(result)
            } catch (error) {
                console.error('Error loading config:', error)
            }
        }
        loadConfig()
    }, [])

    // Get the primary color from the config
    const primaryColor = config?.colorTheme?.primaryColor ?? '#d32f2f'

    if (!props.visible) {
        return null
    }

    const transFailedIcon = cdnHost + '/app/transaction_failed.svg'

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '600px',
            margin: '23px 22px 18px 16px',
            overflow: 'hidden',
        },
        header: {
            background: '#E6E6E6',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '24px',
        },
        icon: {
            width: '80px',
            height: '80px',
            marginBottom: '16px',
        },
        title: {
            fontSize: '24px',
            fontWeight: '700',
            marginBottom: '8px',
            textAlign: 'center',
            color: '#cf0909',
        },
        message: {
            fontSize: '16px',
            color: '#6B7280',
            textAlign: 'center',
            maxWidth: '400px',
            lineHeight: '1.5',
        },
        details: {
            padding: '24px',
            background: 'white',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
        },
        button: {
            backgroundColor: primaryColor,
            color: 'white',
            padding: '10px 20px',
            borderRadius: '4px',
            border: 'none',
            fontSize: '16px',
            fontWeight: '500',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
    }

    return (
        <div style={styles.container} className="failure-box">
            <div style={styles.header}>
                <img src={transFailedIcon} alt="Failed" style={styles.icon} />
                <div style={styles.title}>Payment Declined</div>
            </div>
        </div>
    )
}
