import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const SafeMarkdownContent = ({ content, className, style }) => {
    if (!content) return null

    return (
        <div className={className} style={style}>
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    )
}

SafeMarkdownContent.propTypes = {
    content: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
}

export default SafeMarkdownContent
