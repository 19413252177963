// Maximum length for regex pattern to prevent catastrophic backtracking
const MAX_PATTERN_LENGTH = 256

// Characters that are definitely safe in regex patterns
const SAFE_PATTERN_CHARS = /^[a-zA-Z0-9\s\-_.,!@#$%^&*()[\]{}|:;<>?/\\+=`~'"]*$/

/**
 * Validates if a regex pattern is safe to use
 * @param {string} pattern - The regex pattern to validate
 * @returns {boolean} - True if the pattern is safe, false otherwise
 */
export const isPatternSafe = pattern => {
    if (!pattern || typeof pattern !== 'string') {
        return false
    }

    // Check pattern length
    if (pattern.length > MAX_PATTERN_LENGTH) {
        console.warn('Regex pattern too long, potential ReDoS risk')
        return false
    }

    // Check for safe characters only
    if (!SAFE_PATTERN_CHARS.test(pattern)) {
        console.warn('Regex pattern contains potentially unsafe characters')
        return false
    }

    // Basic checks for common problematic patterns
    const riskyPatterns = [
        /\(\.*\)/, // Nested quantifiers
        /\{\d+,\}/, // Unbounded quantifiers
        /\(\?=.*\)/, // Lookaheads
        /\(\?!.*\)/, // Negative lookaheads
        /\(\?<=.*\)/, // Lookbehinds
        /\(\?<!.*\)/, // Negative lookbehinds
        /\(.*\)\+/, // Repeated groups
        /\(.*\)\*/, // Kleene star on groups
    ]

    for (const risky of riskyPatterns) {
        if (risky.test(pattern)) {
            console.warn('Regex pattern contains potentially problematic constructs')
            return false
        }
    }

    return true
}

/**
 * Creates a safe RegExp object from a pattern
 * @param {string} pattern - The regex pattern to use
 * @param {string} [flags=''] - The regex flags to use
 * @returns {RegExp|null} - A RegExp object if the pattern is safe, null otherwise
 */
export const createSafeRegExp = (pattern, flags = '') => {
    if (!isPatternSafe(pattern)) {
        return null
    }

    try {
        // eslint-disable-next-line security/detect-non-literal-regexp
        return new RegExp(pattern, flags)
    } catch (error) {
        console.warn('Failed to create RegExp:', error)
        return null
    }
}
