import * as yup from 'yup'
import { createSafeRegExp } from './regex-validator'

export const formSchemaBuilder = formFields => {
    // Pre-compile safe regex patterns
    const DIGITS_ONLY = createSafeRegExp('^[0-9]*$')
    const DIGITS_WITH_DECIMALS = createSafeRegExp('^[0-9]*\\.?[0-9]*$')
    const ALPHANUMERIC_ONLY = createSafeRegExp('^[a-zA-Z0-9]*$')

    // Ensure patterns are valid
    if (!DIGITS_ONLY || !DIGITS_WITH_DECIMALS || !ALPHANUMERIC_ONLY) {
        console.error('Failed to create one or more regex patterns')
        throw new Error('Form validation initialization failed')
    }

    const digitsOnly = value => DIGITS_ONLY.test(value)
    const digitsWithDecimals = value => DIGITS_WITH_DECIMALS.test(value)
    const alphanumericOnly = value => ALPHANUMERIC_ONLY.test(value)

    const schema = yup.object().shape(
        formFields.reduce((acc, field) => {
            let validator = yup.string()

            const validation = field.validation

            if (validation.type === 'number') {
                validator = yup.number().nullable().typeError(`Must be a number`)
            }

            if (validation) {
                if (validation.pattern) {
                    const safeRegex = createSafeRegExp(validation.pattern)
                    if (safeRegex) {
                        validator = validator.matches(safeRegex, 'Invalid format')
                    } else {
                        console.warn(`Skipping unsafe regex pattern: ${validation.pattern}`)
                        // Fall back to allowing any input rather than blocking valid data
                        validator = validator.test('unsafe-pattern', 'Invalid format', () => true)
                    }
                }
                if (validation.minLength) {
                    validator = validator.min(validation.minLength, `Should be at least ${validation.minLength} characters long`)
                }

                if (validation.maxLength) {
                    validator = validator.max(validation.maxLength, `Should be no longer than ${validation.maxLength} characters`)
                }
                if (validation.digitsWithDecimals) {
                    validator = validator.test(
                        'digitsWithDecimals',
                        field.errorMessages.digitsWithDecimals || 'Invalid format',
                        digitsWithDecimals,
                    )
                }
                if (validation.alphanumericOnly) {
                    const errorMessage =
                        field.errorMessages && field.errorMessages.alphanumericOnly
                            ? field.errorMessages.alphanumericOnly
                            : 'Invalid format'
                    validator = validator.test('alphanumericOnly', errorMessage, alphanumericOnly)
                }
                if (validation.digitsOnly) {
                    validator = validator.test('digitsOnly', field.errorMessages?.digitsOnly || 'Invalid format', digitsOnly)
                }
            }

            if (validation.type === 'number') {
                if (validation.min) {
                    validator = validator.min(validation.min, field.errorMessages?.min || `Should be at least ${validation.min}`)
                }

                if (validation.max) {
                    validator = validator.max(
                        validation.max,
                        field.errorMessages?.max || `Should be no more than ${validation.max}`,
                    )
                }
            }
            if (field.required) {
                // Set optional to false for required fields
                validator = validator.required(`Is required`)
            } else {
                // Set optional to true for non-required fields
                validator = validator.nullable().optional()
            }

            acc[field.name] = validator
            return acc
        }, {}),
    )
    return schema
}
