import React from 'react'
const currencySymbols = {
    AED: 'د.إ', // UAE Dirham
    AFN: '؋', // Afghan Afghani
    ALL: 'L', // Albanian Lek
    AMD: '֏', // Armenian Dram
    ANG: 'ƒ', // Netherlands Antillean Guilder
    AOA: 'Kz', // Angolan Kwanza
    ARS: '$', // Argentine Peso
    AUD: '$', // Australian Dollar
    AWG: 'ƒ', // Aruban Florin
    AZN: '₼', // Azerbaijani Manat
    BAM: 'KM', // Bosnia-Herzegovina Convertible Mark
    BBD: '$', // Barbadian Dollar
    BDT: '৳', // Bangladeshi Taka
    BGN: 'лв', // Bulgarian Lev
    BHD: '.د.ب', // Bahraini Dinar
    BIF: 'FBu', // Burundian Franc
    BMD: '$', // Bermudan Dollar
    BND: '$', // Brunei Dollar
    BOB: 'Bs.', // Bolivian Boliviano
    BRL: 'R$', // Brazilian Real
    BSD: '$', // Bahamian Dollar
    BTC: '₿', // Bitcoin
    BTN: 'Nu.', // Bhutanese Ngultrum
    BWP: 'P', // Botswanan Pula
    BYN: 'Br', // Belarusian Ruble
    BZD: 'BZ$', // Belize Dollar
    CAD: '$', // Canadian Dollar
    CDF: 'FC', // Congolese Franc
    CHF: 'CHF', // Swiss Franc
    CLP: '$', // Chilean Peso
    CNY: '¥', // Chinese Yuan
    COP: '$', // Colombian Peso
    CRC: '₡', // Costa Rican Colón
    CUC: '$', // Cuban Convertible Peso
    CUP: '₱', // Cuban Peso
    CVE: '$', // Cape Verdean Escudo
    CZK: 'Kč', // Czech Republic Koruna
    DJF: 'Fdj', // Djiboutian Franc
    DKK: 'kr', // Danish Krone
    DOP: 'RD$', // Dominican Peso
    DZD: 'دج', // Algerian Dinar
    EGP: 'E£', // Egyptian Pound
    ERN: 'Nfk', // Eritrean Nakfa
    ETB: 'Br', // Ethiopian Birr
    EUR: '€', // Euro
    FJD: '$', // Fijian Dollar
    FKP: '£', // Falkland Islands Pound
    GBP: '£', // British Pound Sterling
    GEL: '₾', // Georgian Lari
    GGP: '£', // Guernsey Pound
    GHS: '₵', // Ghanaian Cedi
    GIP: '£', // Gibraltar Pound
    GMD: 'D', // Gambian Dalasi
    GNF: 'FG', // Guinean Franc
    GTQ: 'Q', // Guatemalan Quetzal
    GYD: '$', // Guyanaese Dollar
    HKD: '$', // Hong Kong Dollar
    HNL: 'L', // Honduran Lempira
    HRK: 'kn', // Croatian Kuna
    HTG: 'G', // Haitian Gourde
    HUF: 'Ft', // Hungarian Forint
    IDR: 'Rp', // Indonesian Rupiah
    ILS: '₪', // Israeli New Sheqel
    IMP: '£', // Manx pound
    INR: '₹', // Indian Rupee
    IQD: 'ع.د', // Iraqi Dinar
    IRR: '﷼', // Iranian Rial
    ISK: 'kr', // Icelandic Króna
    JEP: '£', // Jersey Pound
    JMD: 'J$', // Jamaican Dollar
    JOD: 'JD', // Jordanian Dinar
    JPY: '¥', // Japanese Yen
    KES: 'KSh', // Kenyan Shilling
    KGS: 'с', // Kyrgystani Som
    KHR: '៛', // Cambodian Riel
    KMF: 'CF', // Comorian Franc
    KPW: '₩', // North Korean Won
    KRW: '₩', // South Korean Won
    KWD: 'KD', // Kuwaiti Dinar
    KYD: '$', // Cayman Islands Dollar
    KZT: '₸', // Kazakhstani Tenge
    LAK: '₭', // Laotian Kip
    LBP: 'ل.ل', // Lebanese Pound
    LKR: '₨', // Sri Lankan Rupee
    LRD: '$', // Liberian Dollar
    LSL: 'M', // Lesotho Loti
    LYD: 'LD', // Libyan Dinar
    MAD: 'MAD', // Moroccan Dirham
    MDL: 'MDL', // Moldovan Leu
    MGA: 'Ar', // Malagasy Ariary
    MKD: 'ден', // Macedonian Denar
    MMK: 'K', // Myanmar Kyat
    MNT: '₮', // Mongolian Tugrik
    MOP: 'MOP$', // Macanese Pataca
    MRU: 'UM', // Mauritanian Ouguiya
    MUR: '₨', // Mauritian Rupee
    MVR: 'Rf', // Maldivian Rufiyaa
    MWK: 'MK', // Malawian Kwacha
    MXN: '$', // Mexican Peso
    MYR: 'RM', // Malaysian Ringgit
    MZN: 'MT', // Mozambican Metical
    NAD: '$', // Namibian Dollar
    NGN: '₦', // Nigerian Naira
    NIO: 'C$', // Nicaraguan Córdoba
    NOK: 'kr', // Norwegian Krone
    NPR: '₨', // Nepalese Rupee
    NZD: '$', // New Zealand Dollar
    OMR: 'ر.ع.', // Omani Rial
    PAB: 'B/.', // Panamanian Balboa
    PEN: 'S/.', // Peruvian Nuevo Sol
    PGK: 'K', // Papua New Guinean Kina
    PHP: '₱', // Philippine Peso
    PKR: '₨', // Pakistani Rupee
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    QAR: 'ر.ق', // Qatari Rial
    RON: 'lei', // Romanian Leu
    RSD: 'дин', // Serbian Dinar
    RUB: '₽', // Russian Ruble
    RWF: 'R₣', // Rwandan Franc
    SAR: 'ر.س', // Saudi Riyal
    SBD: '$', // Solomon Islands Dollar
    SCR: '₨', // Seychellois Rupee
    SDG: 'ج.س.', // Sudanese Pound
    SEK: 'kr', // Swedish Krona
    SGD: '$', // Singapore Dollar
    SHP: '£', // Saint Helena Pound
    SLL: 'Le', // Sierra Leonean Leone
    SOS: 'S', // Somali Shilling
    SRD: '$', // Surinamese Dollar
    SSP: '£', // South Sudanese Pound
    STN: 'Db', // São Tomé and Príncipe Dobra
    SVC: '$', // Salvadoran Colón
    SYP: '£', // Syrian Pound
    SZL: 'E', // Swazi Lilangeni
    THB: '฿', // Thai Baht
    TJS: 'ЅМ', // Tajikistani Somoni
    TMT: 'm', // Turkmenistani Manat
    TND: 'د.ت', // Tunisian Dinar
    TOP: 'T$', // Tongan Paʻanga
    TRY: '₺', // Turkish Lira
    TTD: 'TT$', // Trinidad and Tobago Dollar
    TWD: 'NT$', // New Taiwan Dollar
    TZS: 'TSh', // Tanzanian Shilling
    UAH: '₴', // Ukrainian Hryvnia
    UGX: 'USh', // Ugandan Shilling
    USD: '$', // United States Dollar
    UYU: '$U', // Uruguayan Peso
    UZS: 'лв', // Uzbekistan Som
    VES: 'Bs.S', // Venezuelan Bolívar Soberano
    VND: '₫', // Vietnamese Dong
    VUV: 'VT', // Vanuatu Vatu
    WST: 'WS$', // Samoan Tala
    XAF: 'FCFA', // CFA Franc BEAC
    XCD: '$', // East Caribbean Dollar
    XOF: 'CFA', // CFA Franc BCEAO
    XPF: '₣', // CFP Franc
    YER: '﷼', // Yemeni Rial
    ZAR: 'R', // South African Rand
    ZMW: 'ZK', // Zambian Kwacha
    ZWL: 'Z$', // Zimbabwean Dollar
}

function getCurrencySymbol(currencyCode) {
    return currencySymbols[String(currencyCode)] || '$' // default to '$' if the currency code is not found
}

// usage

export const CurrencySymbol = ({ currencyCode, isCurrency, isField = true }) => {
    const symbol = getCurrencySymbol(currencyCode)
    const className = isField
        ? 'z-10 h-full font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-1 py-1'
        : ''

    return isCurrency ? <span className={className}>{symbol}</span> : null
}
