import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useMountParams } from './useMountParams'
import { formSchemaBuilder } from '../../utils/form-schema-builder'
import { useInputChange } from './useInputChange'
import { useSubmit } from '../../utils/use-submit'
import { FormFields } from './FormFields'
import { useLocation } from 'react-router-dom'
import { retrieveConfig } from '../../config/config'

export default function HostedForm(props) {
    const primaryColor = props.primaryColor || '#334155'
    const secondaryColor = props.secondaryColor || '#4a90e2'
    const formFields = props.formFields
    const schema = formSchemaBuilder(formFields)
    const location = useLocation()
    const [paymentOptionsText, setPaymentOptionsText] = useState(0)
    const [paymentOptionsTextColor, setPaymentOptionsTextColor] = useState(0)
    const [paymentOptionsBackgroundColor, setPaymentOptionsBackgroundColor] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState(0)
    const [paymentOptionsTitle, setPaymentOptionsTitle] = useState(0)
    const [background, setBackground] = useState(0)

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        async function fetchData() {
            const config = await retrieveConfig()

            setPaymentOptionsText(config.form.paymentOptionsText)
            setPaymentOptionsTextColor(config.form.paymentOptionsTextColor)
            setPaymentOptionsBackgroundColor(config.form.paymentOptionsBackgroundColor)
            setBackgroundColor(config.form.backgroundColor)
            setBackground(config.form.background)
            setPaymentOptionsTitle(config.form.title)
        }

        fetchData()

        const params = new URLSearchParams(location.search)
        formFields.forEach(field => {
            const paramValue = params.get(field.name)
            if (paramValue) {
                setValue(field.name, paramValue)
            }
        })
    }, [
        formFields,
        location.search,
        setValue,
        paymentOptionsText,
        setPaymentOptionsText,
        paymentOptionsTextColor,
        setPaymentOptionsTextColor,
        paymentOptionsBackgroundColor,
        setPaymentOptionsBackgroundColor,
        backgroundColor,
        setBackgroundColor,
        background,
        setBackground,
        paymentOptionsTitle,
        setPaymentOptionsTitle,
    ])

    const lockedParams = useMountParams(props.params, setValue)
    const inputChange = useInputChange(formFields, setValue)
    const onSubmit = useSubmit(props.onHandleSubmit)

    // Use useEffect to add and remove event listener to prevent memory leaks
    useEffect(() => {
        const handlePaymentSuccess = () => {
            // Reset form and clear all field values
            formFields.forEach(field => {
                setValue(field.name, '')
            })
            reset()
        }

        document.addEventListener('submittedPayment', handlePaymentSuccess)

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('submittedPayment', handlePaymentSuccess)
        }
    }, [reset, formFields, setValue])

    const payOptionsButtonText = paymentOptionsText ?? 'Payment Options'
    const buttonTextColor = paymentOptionsTextColor ?? 'white'
    const paymentOptionsBgColor = paymentOptionsBackgroundColor ?? secondaryColor
    const formType = props.formType

    return (
        <form
            data-testid="payment-form"
            className="flex flex-2 flex-col bg-gray-100 pt-3 pb-2 pl-4 pr-4 space-y-2"
            style={{ backgroundColor: backgroundColor, backgroundImage: `url(${background})` }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <h4 className="font-semibold text-lg" style={{ color: primaryColor }}>
                {paymentOptionsTitle ?? 'Enter Payment Details'}
            </h4>

            <FormFields
                formFields={formFields}
                errors={errors}
                lockedParams={lockedParams}
                inputChange={inputChange}
                register={register}
                formType={formType}
            />

            <div className="relative mt-3">
                <input
                    data-testid="payment-submit-btn"
                    type="submit"
                    className="w-full py-2 font-medium text-white rounded-lg hover:shadow inline-flex space-x-2 items-center justify-center"
                    style={{ color: buttonTextColor, backgroundColor: paymentOptionsBgColor, cursor: 'pointer' }}
                    value={payOptionsButtonText}
                />
            </div>
        </form>
    )
}
