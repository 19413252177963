import React from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { localFormattedDateTime } from '../../utils/date'
import { paymentTypeIdToLabel } from '../../utils/payment-type-helper'
import _startCase from 'lodash/startCase'
import PaymentSuccess from '../PaymentSuccess'
import { retrieveConfig } from '../../config/config'
import PaymentModelLoader from '../payment-model-loader'
import GeneralError from '../general-error'
import { validate as isValidUUID } from 'uuid'

export default function Receipt() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const chargeId = queryParams.get('charge_id')

    const [rd, setRd] = React.useState(null)
    const [config, setConfig] = React.useState(null)

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        ;(async function loadConfig() {
            const result = await retrieveConfig()
            setConfig(result)
        })()
    }, [])

    React.useEffect(() => {
        getReceiptData(chargeId, setLoading, setError)
            .then(data => {
                if (data.status.status !== 'completed') throw new Error('Payment not completed')
                else setRd(receiptDetails(data))
            })
            .catch(e => {
                setError('Failed to retrieve receipt data.')
                console.error('Failed to retrieve receipt data', e)
            })
    }, [chargeId])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: config?.backgroundColor,
            }}
        >
            <div style={{ width: 480, margin: '80px 0px' }}>
                <GeneralError visible={error} message={error} />
                {loading && <PaymentModelLoader />}
                {!loading && rd && <PaymentSuccess visible={true} chargeId={chargeId} receiptDetails={rd} />}
            </div>
        </div>
    )
}

async function getReceiptData(chargeId, setLoading) {
    if (!isValidUUID(chargeId)) throw new Error('Invalid charge ID')
    setLoading(true)
    return axios
        .get(window.env.REACT_APP_DEMO_BACKEND_URL + '/payment/receipt/' + chargeId, {
            headers: {
                domain: window.location.hostname,
            },
        })
        .then(receiptResponse => receiptResponse.data)
        .finally(() => {
            setLoading(false)
        })
}

export const receiptDetails = receiptData => {
    const cardEndingNumber = receiptData.payments[0].payment_instrument.instrument.card_last_four
    const paymentType = receiptData.payments[0].payment_instrument.instrument_type
    const pspResponse = receiptData.psp_responses[0]

    let payDetails
    if (paymentType === 'card_instrument') {
        payDetails = {
            cardholderName: receiptData.payments[0].payment_instrument.payer.entity_details.name.full_name,
            cardholderNumber: '....' + cardEndingNumber,
            expiryDate: receiptData.payments[0].payment_instrument.instrument.expiration_date,
        }
    } else {
        payDetails = {
            bankName:
                receiptData.payments[0].payment_instrument.instrument.bank_code ||
                _startCase(receiptData.payments[0].payment_instrument.instrument.acquirer),
        }
    }

    // TODO look into passing response message and code, omit for now
    const commonPaymentFields = {
        receiptNo: receiptData.payments[0]?.ledger_id,
        // status: 'Approved',
        // response: 'Honour with identification (08)',
        paymentDate: localFormattedDateTime(),
        paymentInstrument: {
            ...receiptData.payments[0]?.payment_instrument?.instrument,
            instrument_type: receiptData.payments[0]?.payment_instrument?.instrument_type,
        },

        paymentAmount: (receiptData.order?.order_amount?.value / 100).toFixed(2),
        paymentType: paymentTypeIdToLabel(paymentType, pspResponse),
    }

    return {
        ...commonPaymentFields,
        ...payDetails,
    }
}
